import React, { useState } from "react";
import axios from "axios";
import imageSrc from "../assets/agadie-cardiologue.jpg";
import logo from "../assets/81.png";
import logo2 from "../assets/Abbott_Laboratories_logo.jpg";
import logohashtag from "../assets/Asset-6-e1680468829735.png"
import Swal from "sweetalert2";
import '../assets/style.css';
import { Button } from "@material-tailwind/react";


function Inscription() {

  const [name, Setname] = useState("");
  const [prenom, SetPrenom] = useState("");
  const [email, SetEmail] = useState("");
  const [tlf, SetTelephoe] = useState("");
  const [laoding, Setloading] = useState(false);
  const [errors, setErrors] = useState({});
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [showTitleOne, setShowTitleOne] = useState(true);
  const [showTitleTwo, setShowTitleTwo] = useState(false);


  // const validateForm = () => {
  //   let errors = {};
  //   if (!name.trim()) {
  //     errors.name = "Name is required";

  //   }
  //   if (!prenom.trim()) {
  //     errors.prenom = "Prénom is required";
  //   }
  //   if (!email.trim()) {
  //     errors.email = "Email is required";
  //   } else {
  //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  //     if (!emailRegex.test(email)) {
  //       errors.email = "Invalid email format";
  //     }
  //   }
  //   if (!tlf.trim()) {
  //     errors.tlf = "Téléphone is required";
  //   }
  //   if (!ville.trim()) {
  //     errors.ville = "Ville is required";
  //   }
  //   setFormErrors(errors);
  //   return Object.keys(errors).length === 0;
  // };



  const addusers = async (e) => {
    e.preventDefault();
    Setloading(true);
    const user = {
      name,
      prenom,
      email,
      tlf,
    };

    try {
      await axios.post(
        `https://pisudecho24s.hashtagsante.com/api/inscrit`,
        user
      );
      setShowSuccessAlert(true);
      Setloading(false);
      setShowForm(false);
      setShowTitleTwo(true);
      setShowTitleOne(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        const errors = error.response.data.errors;
        const errorList = Object.values(errors);
        // Format error messages as a list
        const formattedErrors = errorList.map((errorMsg) => `- ${errorMsg}`).join('\n');

        Swal.fire({
          icon: 'error',
          html: `<pre>${formattedErrors}</pre>`,

        });
      } else {
        // Handle other types of errors here
        Swal.fire({
          icon: 'error',
          text: 'An error occurred. Please try again later.',
        });
      }
      Setloading(false);
    }
  };


  const handleAlertClose = () => {
    setShowSuccessAlert(false);
  };

  return (
    <section className="bg-white">
      <div className="lg:grid lg:min-h-screen lg:grid-cols-12">
       
       
        <aside className="relative block h-64 lg:order-last lg:col-span-5 lg:h-full xl:col-span-6">
          
        <img style={{ width: "300px" }} alt="" src={logo} className="logo_mobile"  />           <img
            alt=""
            src={imageSrc}
            className="testimg"
            //  className="absolute inset-0 h-full w-full  object-cover"
          />
        </aside>
        <main className="flex items-center justify-center px-8 py-8 sm:px-12 lg:col-span-7 lg:px-16 lg:py-12 xl:col-span-6">
          <div className="max-w-xl lg:max-w-3xl">


            <div class="grid ">
              <div class="col-start-1 col-end-3 ..."> <a className="block text-blue-600" >
                <img style={{ width: "300px" }} alt="" src={logo} className="logoinscri" />
              </a></div>
              <div class="col-end-7 col-span-2 ...">
           

               </div>
            </div>

            {showTitleOne && (
             <h1  className="mt-6 pt-4 text-2xl font-bold text-gray-900 sm:text-2xl md:text-3xl style_h1">
             Pré-inscription pour recevoir pré-programme.
             <br />
             <br />
         </h1>
            )}
            {showTitleTwo && (
              <h1 className="mt-6 pt-4 text-2xl font-bold text-gray-900 sm:text-3xl md:text-4xl">
                Parfait !
                <br></br>
              </h1>
            )}
            <br></br>

            {showSuccessAlert && (


              <div
                id="alert-additional-content-3"
                class="p-4 mb-4 text-green-800 border border-t-4 border-green-300 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400 dark:border-green-800"
                role="alert"
              >
                <div class="flex items-center">
                  <svg class="fill-current w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z" /></svg>

                  <span class="sr-only">Info</span>
                  <h3 class="text-lg font-medium">Félicitations .</h3>
                </div>
                <div class="mt-2 mb-4 text-sm font-medium">
                  Vous êtes inscrit(e), prière de vérifier votre Email pour plus d'informations !.{" "}
                </div>
                <div class="flex"></div>
              </div>
            )}

            {Object.keys(errors).length > 1 ? (
              <div
                id="alert-2"
                class="flex p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert"
              >
                <svg
                  class="flex-shrink-0 inline w-4 h-4 me-3 mt-[2px]"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <span class="sr-only">Danger</span>
                <div>
                  <span class="font-medium">
                    Assurez-vous que ces exigences sont remplies :
                  </span>
                  <ul className="list-disc ml-4">
                    {" "}
                    {/* Add Tailwind classes to style the <ul> */}
                    {Object.keys(errors).map((key) =>
                      errors[key].map((errorMessage, index) => (
                        <li key={index}>{errorMessage}</li>
                      ))
                    )}
                  </ul>
                </div>
                <button
                  type="button"
                  class="ms-auto -mx-1.5 -my-1.5 bg-red-50 text-red-500 rounded-lg focus:ring-2 focus:ring-red-400 p-1.5 hover:bg-red-200 inline-flex items-center justify-center h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700"
                  aria-label="Close"
                  onClick={() =>
                    (document.getElementById("alert-2").style.display = "none")
                  }
                >
                  <span class="sr-only">Close</span>
                  <svg
                    class="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                </button>
              </div>
            ) : (
              ""
            )}

            {showForm && (
              <form className="mt-8 grid grid-cols-6 gap-6" onSubmit={addusers}>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="FirstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Nom*
                  </label>

                  <input
                    type="text"
                    id="name"
                    value={name}
                    onChange={(e) => Setname(e.target.value)}
                    className={`border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 ${formErrors.ville ? 'error' : ''}`}
                  />
                  {formErrors.name && <p style={{ color: 'red' }}>{formErrors.name}</p>}
                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="LastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Prénom*
                  </label>

                  <input
                    type="text"
                    id="prename"
                    value={prenom}
                    onChange={(e) => SetPrenom(e.target.value)}
                    class=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {formErrors.prenom && <p style={{ color: 'red' }}>{formErrors.prenom}</p>}

                </div>
                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="FirstName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email*
                  </label>

                  <input
                    type="text"
                    id="email"
                    value={email}
                    onChange={(e) => SetEmail(e.target.value)}
                    class=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {formErrors.email && <p style={{ color: 'red' }}>{formErrors.email}</p>}

                </div>

                <div className="col-span-6 sm:col-span-3">
                  <label
                    htmlFor="LastName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Gsm*
                  </label>

                  <input
                    type="number"
                    id="tlf"
                    value={tlf}
                    onChange={(e) => SetTelephoe(e.target.value)}
                    class=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  />
                  {formErrors.tlf && <p style={{ color: 'red' }}>{formErrors.tlf}</p>}

                </div>




                <div className="col-span-6 sm:flex sm:items-center sm:gap-4">
                  {laoding ? (
                    <div role="status">
                      <svg
                        aria-hidden="true"
                        class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="inline-block shrink-0 rounded-md border border-blue-600 bg-blue-600 px-12 py-3 text-sm font-medium text-white transition hover:bg-transparent hover:text-blue-600 focus:outline-none focus:ring active:text-blue-500"
                    >
                      S'inscrire
                    </button>
                  )}
                </div>
              </form>
            )}
          </div>
        </main>
      </div>
      <footer className="text-center text-white bg-blue-900">
        <div
          className="p-4 text-center bg-black bg-opacity-20">
          © 2024  SUDECHO - DEVELOPED BY
          <a className="text-white" href="https://HASHTAGSANTE.COM/"
          > HASHTAGSANTE
          </a>

        </div>
        {/* <div className="p-4 text-center bg-black bg-opacity-20">
        <span>© 2024 SUDECHO - DEVELOPED BY</span>
        <img style={{ display: "inline-block", width: "100px", verticalAlign: "middle" }} alt="" src={logohashtag} className="" />
      </div> */}
      </footer>

    </section>
  );
}

export default Inscription;
